<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Bill Generate Report
            <span class="statement-head"
              ><v-menu offset-y transition="slide-y-reverse-transition">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" text
                    >{{ selected_statement_type }}
                    <v-icon ml-2>arrow_drop_down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-subheader>Select Type</v-subheader>
                  <v-list-item
                    v-for="(item, index) in statementTypes"
                    :key="index"
                    @click="(selected_statement_type = item), reset()"
                  >
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </span>
            <v-spacer></v-spacer>
            <v-download-column-select
              v-if="this.items.length > 0 || this.feeHeadItems.length > 0"
              :downloadColumns="downloadColumns"
              @onDownload="downloadCollection"
              :downloadExcelText="'Download Report'"
              :showPDF="false"
            >
              DOWNLOAD BILLING RECORD
            </v-download-column-select>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title pb-0">
              <v-flex xs3 sm3 v-if="selected_statement_type === 'Month'">
                <v-select
                  :items="months"
                  class="pa-0"
                  label="Select Month"
                  v-model="month"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs2 sm2 v-if="selected_statement_type === 'SOD'">
                <v-calendar-field
                  id-val="fdate"
                  v-model="fdate"
                  label="From Date"
                >
                </v-calendar-field>
              </v-flex>
              <v-flex xs2 sm2 v-if="selected_statement_type === 'SOD'">
                <v-calendar-field
                  id-val="tdate"
                  v-model="tdate"
                  label="To Date"
                >
                </v-calendar-field>
              </v-flex>
              <v-flex xs2 sm2>
                <v-grade-field
                  label="Select Grades"
                  :with-all="true"
                  v-model="gradeId"
                  @selectedGrade="selectedGrade"
                >
                </v-grade-field>
              </v-flex>
              <v-flex xs2 sm2>
                <v-select
                  :items="invoices"
                  class="pa-0"
                  label="Invoice Type"
                  v-model="invoice"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs2 sm2>
                <v-select
                  :items="filterWise"
                  class="pa-0"
                  label=" Filter By"
                  v-model="filterByTemp"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs2 sm2>
                <search-button
                  @action="get()"
                  :disabled="!gradeId"
                  :permission="true"
                  >Search</search-button
                >
              </v-flex>
            </v-card-title>
          </v-card>
          <v-card>
            <v-flex>
              <div id="billChart">
                <div
                  v-for="(item, $index) in chartDetails"
                  :key="$index"
                  :class="{
                    [getBgColor($index)]: true,
                    is_extra_small: percentage[$index] < 20,
                  }"
                  :style="{
                    width: percentage[$index] + '%',
                  }"
                >
                  &nbsp;
                  <span :class="{ is_extra_small: percentage[$index] < 20 }"
                    >{{
                      item.bill_month
                        ? getMonthNameFromString(item.bill_month)
                        : 'General Invoice'
                    }}
                    ({{ item.amount.currency() }} /
                    {{ percentage[$index] + '%' }})</span
                  >
                </div>
              </div>
            </v-flex>
          </v-card>

          <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="pagination.totalItems"
            v-if="filterBy === 'student'"
          >
            <template v-slot:item="{ index, item }">
              <tr
                v-if="filterBy === 'student'"
                style="cursor: pointer"
                @click="viewDetails(item.invoice_no, item.cancelled_invoice_no)"
              >
                <td>{{ ++index }}</td>

                <td class="text-xs-left">
                  <strong>
                    {{ item.enroll_code ? item.enroll_code : '-' }}</strong
                  >
                </td>
                <td class="text-xs-left">
                  <strong>{{
                    item.invoice_no
                      ? item.invoice_no
                      : item.cancelled_invoice_no
                  }}</strong>
                </td>
                <td class="text-xs-left">
                  <strong>{{ item.name ? item.name : '-' }}</strong>
                </td>
                <td class="text-xs-left">
                  <strong>{{
                    item.grade ? item.grade.replaceAll('"', '') : '-'
                  }}</strong
                  ><sup>{{
                    item.section ? item.section.replaceAll('"', '') : '-'
                  }}</sup>
                </td>

                <td class="text-xs-left">
                  <strong>{{
                    item.amount ? item.amount.currency() : '-'
                  }}</strong>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-data-table
            :headers="feeHeadHeaders"
            :items="feeHeadItems"
            hide-default-footer
            :server-items-length="feeHeadItems.length"
            v-if="filterBy === 'fee_head'"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ ++index }}</td>
                <td class="text-left">
                  <strong> {{ item.fee_head ? item.fee_head : '' }}</strong>
                </td>
                <td class="text-right">
                  <strong>{{
                    item.amount ? item.amount.currency() : '-'
                  }}</strong>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="detailDialogue" persistent max-width="1000px">
      <v-card>
        <v-card-title class="success white--text">Invoice Details</v-card-title>
        <v-card-text class="pb-1 pt-4">
          <v-flex xs12>
            <table
              class="basic_styled_table"
              width="100%"
              cellpadding="5"
              cellspacing="5"
            >
              <thead>
                <tr>
                  <th>Fee Head</th>
                  <th>Fee Amount</th>
                  <th>Sch./Dsc.</th>
                  <th>Total Amount</th>
                  <th>Paid Amount</th>
                  <th>Due Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, key) in details" :key="key">
                  <td>
                    <strong>{{ item.fee_head ? item.fee_head : '-' }}</strong>
                  </td>
                  <td>
                    <strong>{{
                      item.fee_head_amount
                        ? item.fee_head_amount.currency()
                        : '-'
                    }}</strong>
                  </td>
                  <td>
                    <strong>
                      {{
                        item.scholarship ? item.scholarship.currency() : '-'
                      }}/{{ item.discount ? item.discount.currency() : '-' }}
                    </strong>
                  </td>
                  <td>
                    <strong>
                      {{ item.total ? item.total.currency() : '-' }}</strong
                    >
                  </td>
                  <td>
                    <strong>
                      {{
                        item.paid_amount ? item.paid_amount.currency() : '-'
                      }}</strong
                    >
                  </td>

                  <td>
                    <strong>
                      {{
                        item.due_amount ? item.due_amount.currency() : '-'
                      }}</strong
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="detailDialogue = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Mixins from '@/library/Mixins';
import { redirectDownloadUrl, getMonthNameFromString } from '@/library/helpers';

export default {
	mixins: [Mixins],
	data: () => ({
		fdate: '',
		tdate: '',
		gradeId: '',
		statement_types: ['Month', 'SOD'],
		selected_statement_type: 'Month',
		grades: [],
		sectionId: '',
		month: '',
		items: [],
		feeHeadItems: [],
		detailDialogue: false,
		details: [],
		pagination: {
			totalItems: 0,
			itemsPerPage: 25,
		},
		chartDetails: [],
		percentage: [],
		months: [
			{ value: '01', text: 'Baishakh' },
			{ value: '02', text: 'Jestha' },
			{ value: '03', text: 'Ashad' },
			{ value: '04', text: 'Shrawan' },
			{ value: '05', text: 'Bhadra' },
			{ value: '06', text: 'Asoj' },
			{ value: '07', text: 'Kartik' },
			{ value: '08', text: 'Mangsir' },
			{ value: '09', text: 'Poush' },
			{ value: '10', text: 'Magh' },
			{ value: '11', text: 'Falgun' },
			{ value: '12', text: 'Chaitra' },
			{ value: '13', text: 'Baishakh(13)' },
		],

		invoice: '',
		invoices: [
			{ value: 'generated', text: 'Generated' },
			{ value: 'rollback', text: 'RollBack' },
		],
		filterBy: '',
		filterByTemp: '',

		filterWise: [
			{ value: 'student', text: 'Student' },
			{ value: 'fee_head', text: 'Fee Head' },
		],
		downloadColumns: [],
		headers: [
			{ text: '#', align: 'center', value: 'id', width: 50, sortable: false },
			{
				text: 'Enroll Code',
				align: 'left',
				value: 'enroll_code',
				width: 150,
				sortable: false,
			},
			{
				text: 'Invoice No.',
				align: 'left',
				value: 'invoice_no',
				width: 150,
				sortable: false,
			},
			{
				text: 'Student Name',
				align: 'left',
				value: 'name',
				width: 300,
				sortable: false,
			},
			{
				text: 'Grade/Section',
				align: 'left',
				value: 'section',
				width: 150,
				sortable: false,
			},
			{
				text: 'Amount',
				align: 'left',
				value: 'fee_amount',
				width: 175,
				sortable: false,
			},
		],
		feeHeadHeaders: [
			{ text: '#', align: 'left', value: 'id', width: 50, sortable: false },
			{
				text: 'Fee Head',
				align: 'left',
				value: 'fee_head',
				width: 150,
				sortable: false,
			},
			{
				text: 'Amount',
				align: 'right',
				value: 'fee_amount',
				width: 175,
				sortable: false,
			},
		],
		colorClasses: [
			'bg-violet',
			'bg-indigo',
			'bg-blue',
			'bg-green',
			'bg-yellow',
			'bg-orange',
			'bg-red',
			'bg-violet',
			'bg-indigo',
			'bg-blue',
			'bg-green',
			'bg-yellow',
			'bg-orange',
			'bg-red',
		],
		batch: {},
	}),
	watch: {
		pagination: function () {
			this.get();
		},
	},
	computed: {
		// dynamicColumns() {
		//   let columns = [];
		//   this.downloadColumns.map((item) => {
		//     columns.push(item);
		//   });
		//   return columns;
		// },

		statementTypes() {
			let $this = this;
			return this.statement_types.filter(function (statement) {
				return $this.selected_statement_type !== statement;
			});
		},
	},
	mounted() {
		this.batch = JSON.parse(window.localStorage.getItem('batch'));
		this.getGrades();
		this.billChartReport();
	},

	methods: {
		selectedGrade(grade) {
			let sectionId = '';
			this.sectionId = '';
			grade.sections.map((item) => {
				sectionId += item.id + ',';
			});
			this.sectionId = sectionId;
		},
		reset() {
			this.month = '';
			this.fdate = '';
			this.tdate = '';
		},
		getMonthNameFromString,
		get() {
			this.filterBy = this.filterByTemp;
			this.items = [];
			this.feeHeadItems = [];
			let extraParam = this.queryString(
				`grade=${this.gradeId.toString()}&month=${this.month}&sectionId=${
					this.sectionId
				}&fromDate=${this.fdate.dateForm()}&toDate=${this.tdate.dateForm()}&invoice=${
					this.invoice
				}&filterBy=${this.filterBy}`
			);
			this.$rest
				.get(`/api/report/bill-generate-report${extraParam}`)
				.then(({ data }) => {
					if (this.filterBy === 'student') {
						this.items = data.data;
						this.pagination.totalItems = data.meta.total;
					} else {
						this.feeHeadItems = data.data;
					}
				});
		},
		viewDetails(invoice, cancelled_invoice_no) {
			let extraParams;
			if (invoice) {
				extraParams = this.queryString(`invoice=${invoice}`);
			}
			if (cancelled_invoice_no) {
				extraParams = this.queryString(
					`cancel_invoice=${cancelled_invoice_no}`
				);
			}
			this.$rest
				.get(`/api/report/bill-generate-report-details${extraParams}`)
				.then(({ data }) => {
					this.details = data.data;
					if (this.details) {
						this.detailDialogue = true;
					}
				});
		},
		getGrades() {
			this.gradeLoading = true;
			this.$rest
				.get(
					'/api/grades?rowsPerPage=25&sortBy=rank&descending=false&slim=true&noBatch=true'
				)
				.then(({ data }) => {
					this.grades = [];
					data.data.map((item) => {
						this.grades.push({ value: item.id, text: item.name });
					});
				})
				.finally(() => {
					this.gradeLoading = false;
				});
		},
		downloadCollection({ type, columns, columnString }) {
			redirectDownloadUrl({
				uri: '/download/billing/bill-generate-report',
				queryString: `type=${type}&column=${columnString}&grade=${this.gradeId}&sectionId=${this.sectionId}&fromDate=${this.fdate}&toDate=${this.tdate}&month=${this.month}&invoice=${this.invoice}&filterBy=${this.filterByTemp} `,
			});
		},

		billChartReport() {
			let extraParam = this.queryString(
				`batchStart=${this.batch.start}&batchEnd=${this.batch.end}`
			);
			this.$rest
				.get(`api/report/bill-chart-report${extraParam}`)
				.then(({ data }) => {
					this.chartDetails = data.data;
					this.percentage = data.percentage;
				});
		},
		getBgColor: function (index) {
			// Get length of  classes array
			let length = this.colorClasses.length;

			// Get the current turn (how many times the classes have been used from start to finish)
			let turn = Math.floor(index / length);

			// Multiply turn by the length then subtract result from current index
			let colorIndex = index - turn * length;

			return this.colorClasses[colorIndex];
		},
	},
};
</script>

<style lang="scss">
#billChart {
  border: 2px solid white;
  overflow: hidden;
  width: 100%;
  display: flex;
  min-height: 35px;

  align-items: center;
  justify-content: center;
}
#billChart div {
  cursor: pointer;
  color: white;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  span {
    &.is_extra_small {
      display: none;
    }
  }
  &:hover {
    height: 30px;
    span {
      display: block;
    }
  }
  &.is_extra_small {
    &:hover {
      transition-duration: 0.1s;
      width: 300px !important;
    }
  }
}
.bg-violet {
  background-color: violet;
}
.bg-indigo {
  background-color: indigo;
}
.bg-blue {
  background-color: blue;
}

.bg-red {
  background-color: red;
}

.bg-orange {
  background-color: rgb(252, 181, 0);
}

.bg-yellow {
  background-color: #ff4800ee;
}

.bg-green {
  background-color: green;
}
</style>
